<template lang="html">
    <div style="height:100%">
        <!-- 第一行 搜索栏 -->
        <div class="handleBox flex flex-jc-sb flex-ai-c">
            <p class="title">统计概况 ></p>
        </div>
        <!-- 表格 -->
        <div class="table" style="height:calc( 100% - 95px );overflow-y:auto">
            <el-table :data="table.tableData" highlight-current-row stripe border
                :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}"
                @sort-change='getList()'
                v-for="(table,tindex) in tables" :key="tindex">
                <template v-for="(item,index) in table.tableHead">
                    <el-table-column :prop="item.column_name" :label="item.column_comment" :key="index" v-if="item.column_name != 'id'" align="center"></el-table-column>
                </template>
            </el-table>

            <div class="overviewbox">
                <div class="ovtitle">
                    <img src="../assets/hk.jpg" alt="">
                    <span>视频播放次数对比</span>
                    <div style="margin-left:auto">
                        <el-select v-model="grade_id" placeholder="选择年级" size="mini" @change="getData()" style="margin-left:10px">
                            <el-option v-for="(item,index) in gradeList" :label="item.name" :value="item.id" :key="index"></el-option>
                        </el-select>
                        <el-select v-model="subjects_id" placeholder="选择科目" size="mini" @change="getData()" style="margin-left:10px">
                            <el-option v-for="(item,index) in subjectsList" :label="item.name" :value="item.id" :key="index"></el-option>
                        </el-select>
                        <el-select v-model="chapter_id" placeholder="选择章节" size="mini" @change="getData()" style="margin-left:10px">
                            <el-option v-for="(item,index) in chapterList" :label="item.name" :value="item.id" :key="index"></el-option>
                        </el-select>
                    </div>
                </div>
                <div id="chart" :style="{width: '98%', height: '90%'}"></div>
            </div>
        </div>
    </div>
</template>

<script>
var echarts = require('echarts');
export default {
    data: function() {
        return {
            list: [],
            gradeList: [],
            subjectsList: [],
            chapterList: [],
            tableHead: [],
            tableData: [],

            tables: [],

            grade_id: '',
            subjects_id: '',
            chapter_id: '',

            count: 0,
        }
    },
    mounted: function() {
        this.getList();
        this.getSelectList('grade');
        this.getSelectList('subjects');
        this.getSelectList('chapter');

        window.onresize = () => { //  根据窗口大小调整曲线大小
            let myChart = echarts.init(document.getElementById('chart'));
            myChart.resize();
        }
    },
    destroyed() {
        window.onresize = null;
    },
    watch: {
        count() {
            if(this.count == 3) {
                this.getData()
            }
        }
    },
    methods: {
        getSelectList(type) {
            this.http.post('/admin.setting/getlist', {
                type
            }).then(re => {
                if(type == 'grade') {
                    this.gradeList = re;
                    this.grade_id = this.gradeList[0].id;
                } else if(type == 'subjects') {
                    this.subjectsList = re;
                    this.subjects_id = this.subjectsList[0].id;
                } else if(type == 'chapter') {
                    this.chapterList = re;
                    this.chapter_id = this.chapterList[0].id;
                }
                this.count++;
            })
        },
        getList() {
            this.http.post('/admin.statistics/getList').then(re => {
                this.tables = re;
            })
        },
        getData() {
            this.http.post('/admin.statistics/bar', {
                grade_id: this.grade_id,
                subjects_id: this.subjects_id,
                chapter_id: this.chapter_id
            }).then((re) => {
                this.drawLine(re.xAxis, re.xAxis1, re.yAxis);
            })
        },
        // 柱图
        drawLine: function(arr1, arr11, arr2) {
            var myChart = echarts.init(document.getElementById('chart'));
            myChart.setOption({
                color: ['#3398DB'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    data: arr1,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: {
                        interval: 0, //横轴信息全部显示
                        rotate: -15, //-15度角倾斜显示
                        formatter: function(value) { //关键代码
                            var res = value
                            if(res.length > 5) {
                                res = res.substring(0, 5) + '..'
                            }
                            return res
                        },
                    },
                }],
                yAxis: [{
                    type: 'value'
                }],
                series: [{
                    name: '次数',
                    type: 'bar',
                    barWidth: '10%',
                    data: arr2
                }]
            });
        },

    }
}
</script>
<style lang="css" scoped>
.el-table{
    margin-bottom: 10px;
}
</style>
